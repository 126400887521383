@use "./variables";
@use "./mixins";

.navigation-container {
    width: 100%;
    position: fixed;
    font-family: variables.$montserrat;
    z-index: 1000;
    @include mixins.transition;

    &.scrolled {
        background-color: variables.$white;
    }
}

.navigation {
    @include mixins.default-grid(variables.$padding-large-desktop);
    @include mixins.transition;
    padding: variables.$padding-large-desktop 0;
    grid-auto-flow: column;

    @media screen and (max-width: variables.$desktop-med) {
        @include mixins.default-grid(variables.$padding-med-desktop);
        padding: variables.$padding-med-desktop 0;
    }

    @media screen and (max-width: variables.$mobile) {
        @include mixins.default-grid(variables.$padding-mobile);
        padding: variables.$padding-mobile 0;
    }
}

.navigation-content {
    @include mixins.default-grid-location;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        width: 64px;
    }

    nav {
        .nav-link {
            padding-left: 45px;
            font-weight: bold;
            color: variables.$black;
            cursor: pointer;
            @include mixins.transition;
        }
        .nav-link:hover {
            color: #588157;
        }
    }
}
