@use "./mixins";
@use "./variables";

.work-section-container {
    width: 100%;
    padding: variables.$padding-large-desktop 0;
}

.work-section {
    @include mixins.default-grid(variables.$padding-large-desktop);

    @media screen and (max-width: variables.$desktop-med) {
        @include mixins.default-grid(variables.$padding-med-desktop);
    }

    @media screen and (max-width: variables.$mobile) {
        @include mixins.default-grid(variables.$padding-mobile);
    }
}

.work-section-content {
    @include mixins.default-grid-location;

    h1 {
        @include mixins.section-title(
            variables.$inter,
            variables.$padding-large-desktop
        );
    }

    .work-section-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
        color: #eee;
        grid-gap: 45px;
    }
}
