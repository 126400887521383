@use "./variables";
@use "./mixins";

.welcome-section-container {
    width: 100%;
    background-image: radial-gradient(
            circle at bottom left,
            rgba(239, 239, 239, 0.8) 35%,
            rgba(239, 239, 239, 0) 100%
        ),
        url("../images/welcome-section-background.webp");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top right;
    @include mixins.transition;

    @media screen and (max-width: variables.$mobile) {
        background-position: top center;
    }
}

.welcome-section {
    @include mixins.default-grid(variables.$padding-large-desktop);

    @media screen and (max-width: variables.$desktop-med) {
        @include mixins.default-grid(variables.$padding-med-desktop);
    }

    @media screen and (max-width: variables.$mobile) {
        @include mixins.default-grid(variables.$padding-mobile);
    }
}

.welcome-section-content {
    @include mixins.default-grid-location;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    justify-content: flex-end;
    height: calc(100vh - variables.$padding-large-desktop);
    padding-bottom: variables.$padding-large-desktop;

    h1 {
        font-size: 4em;
        font-family: variables.$inter;
        line-height: 1;
    }

    h2 {
        line-height: 1;
        padding-bottom: 45px;
    }

    hr {
        height: 2px;
        width: 45px;
        background-color: variables.$accent;
        margin: 20px 0;
    }

    p {
        max-width: 600px;
        line-height: 1.75;
        padding-right: 30px;

        strong {
            font-weight: bold;
            color: variables.$accent;
        }
    }

    .scroll-down-icon {
        align-self: flex-end;
        position: absolute;
        animation: scroll-down 1s ease-in-out 0s infinite alternate forwards;
    }
    @keyframes scroll-down {
        0% {
            transform: translateY(-10px);
        }

        100% {
            transform: translateY(0px);
        }
    }

    @media screen and (max-width: variables.$desktop-med) {
        height: calc(100vh - variables.$padding-med-desktop);
        padding-bottom: variables.$padding-med-desktop;
    }

    @media screen and (max-width: variables.$mobile) {
        height: calc(100vh - variables.$padding-mobile);
        padding-bottom: variables.$padding-mobile;
    }
}
