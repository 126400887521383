// Default grid layout for all components
@mixin default-grid($padding) {
    display: grid;
    grid-template-columns:
        minmax($padding, auto)
        minmax(auto, 1920px)
        minmax($padding, auto);
}

@mixin default-grid-location {
    grid-area: 1 / 2 / 2 / 3;
}

// Transitions
@mixin transition {
    transition: all 0.5s ease-in-out;
}

// Fonts
@mixin section-title($font, $margin) {
    font-size: 4em;
    font-weight: bold;
    font-family: $font;
    line-height: 1;
    margin-bottom: $margin;
}
