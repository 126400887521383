@use "./mixins";
@use "./variables";

.contact-container {
    padding: variables.$padding-large-desktop 0;
    background-color: variables.$black;
}

.contact {
    @include mixins.default-grid(variables.$padding-large-desktop);

    @media screen and (max-width: variables.$desktop-med) {
        @include mixins.default-grid(variables.$padding-med-desktop);
    }

    @media screen and (max-width: variables.$mobile) {
        @include mixins.default-grid(variables.$padding-mobile);
    }
}

.contact-content {
    @include mixins.default-grid-location;
    color: variables.$white;

    h1 {
        @include mixins.section-title(
            variables.$inter,
            variables.$padding-large-desktop
        );
    }

    p {
        max-width: 600px;
        line-height: 1.75;

        span {
            color: variables.$accent;
            font-weight: bold;
        }
    }

    a {
        color: variables.$white;
        display: flex;
        flex-flow: column;
        align-content: flex-start;
        align-items: first baseline;

        @media screen and (max-width: variables.$mobile) {
            text-align: center;
            align-items: center;
        }
    }

    @media screen and (max-width: variables.$mobile) {
        text-align: center;
    }
}

.contact-icon {
    height: 32px;
    color: variables.$white;
    padding: 30px 0;
    @include mixins.transition;

    &:hover {
        color: variables.$accent;
    }

    @media screen and (max-width: variables.$mobile) {
        align-self: center;
    }
}
