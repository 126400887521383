@use "./assets/styles/variables";

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    border: none;
}

body {
    font-family: variables.$work-sans;
    font-size: 16px;
    letter-spacing: 1px;
    -webkit-overflow-scrolling: touch;
}

#work,
#contact {
    transform: translateZ(0);
}
