@use "./mixins";
@use "./variables";

.portfolio-card-container {
    filter: grayscale(100%);
    cursor: pointer;
    @include mixins.transition;
    background-size: cover;

    &.mike-plante-productions {
        background-image: url("../images/mikeplanteproductions.webp");
    }
    &.resource-benefits-inc {
        background-image: url("../images/resourcebenefitsinc.webp");
    }
    &.southbank-johnnies {
        background-image: url("../images/southbankjohnnies.webp");
    }
    &.pier-1515-restaurant {
        background-image: url("../images/pier1515restaurant.webp");
    }
    &.kt-youth-foundation {
        background-image: url("../images/ktyouthfoundation.webp");
    }
    &:hover {
        filter: grayscale(0%);
    }
    a {
        color: variables.$white;
    }
}

.portfolio-card {
    height: 70vh;
    padding: 45px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    h3 {
        font-family: variables.$work-sans;
        font-weight: 400;
    }
    h2 {
        font-size: 2.5em;
        font-family: variables.$inter;
    }
}

.portfolio-card-content {
    padding: 45px;
    display: flex;
}
