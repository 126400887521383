@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Montserrat:wght@200;400;700&family=Work+Sans:wght@400;600&display=swap");

//Fonts
$montserrat: "Montserrat", sans-serif;
$work-sans: "Work Sans", sans-serif;
$inter: "Inter", sans-serif;

// Colors

$white: #eeeeee;
$black: #0f1108;
$accent: #588157;

// Sizing
$padding-large-desktop: 60px;
$padding-med-desktop: 45px;
$padding-mobile: 20px;
$padding-mid: 30px;

//Media Queries
$desktop-large: 1680px;
$desktop-med: 1440px;
$mobile: 480px;
