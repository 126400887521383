@use "./assets/styles/variables";

body {
    background-color: variables.$white;
    color: variables.$black;
}

.app {
    opacity: 0;
    transition: all 2.5s ease-in-out;
}

.app.visible {
    opacity: 1;
}
